export const employmentPeriod = [
  { value: "less_one_year", label: "1年未満" },
  { value: "one_to_three_year", label: "1～3年" },
  { value: "three_to_five_year", label: "3～5年" },
  { value: "over_five_year", label: "5年以上" },
];
export const employmentPeriodConst = [
  { value: "less_one_year", label: "1年未満" },
  { value: "one_to_three_year", label: "1～3年" },
  { value: "three_to_five_year", label: "3～5年" },
  { value: "over_five_year", label: "5年以上" },
] as const;
export type EmploymentPeriod = (typeof employmentPeriodConst)[number]["value"];
