import { z } from "zod";

import { errorMessage, i18n } from "@/i18n";
import { encodeImageToBase64 } from "@/util";

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.invalid_type) {
    if (issue.received === "undefined") {
      return { message: "必須入力です。" };
    }
  }
  if (issue.code === z.ZodIssueCode.invalid_date) {
    return { message: "日付の形式ではありません。" };
  }
  if (issue.code === z.ZodIssueCode.invalid_literal) {
    return { message: "入力値が正しくありません。" };
  }

  return { message: ctx.defaultError };
};

z.setErrorMap(customErrorMap);

// 正規表現パターン
const alphanumericPattern = /^[\u0021-\u007e]+$/u; // 半角英数字記号のみ
const fullwidthCaracterPattern = /^[^\x01-\x7E\xA1-\xDF]+$/; // 全角のみ
const specialSymbolPattern = /[^!@#$%^&*()_+{}\[\]:;<>,.?~]/; // 特殊記号除外
const kanaPattern = /^[ァ-ヶー]*$/; // カナのみ
const phonePattern = /^0\d{9,10}$/; // 国内電話番号
const zipCodePattern = /^\d{7}$/; // 国内郵便番号
const sevenDigitPattern = /^\d{7}$/; // 7桁の半角数字
const streetPattern = /^.*?[0-9０-９]+.*?[-－ー−].*?$/; // 番地入力を許容
const buildingNamePattern = /^[a-zA-Z0-9０-９ぁ-んァ-ン一-龠々〆〤ー－－\s]+$/; // 建物入力を許容

// 必須入力バリデーション
const requiredValidation = (label: string) =>
  z
    .string({ required_error: `${label}を入力してください。` })
    .trim()
    .nonempty(`${label}を入力してください。`);

export const emailValidation = () =>
  requiredValidation(i18n("email"))
    .email({ message: `${i18n("email")}の形式ではありません。` })
    .regex(alphanumericPattern, {
      message: errorMessage("onlyAlphanumeric"),
    });

export const lastNameValidation = () =>
  requiredValidation(i18n("lastName"))
    .regex(specialSymbolPattern, {
      message: errorMessage("cantUseSpecialSymbol"),
    })
    .regex(fullwidthCaracterPattern, {
      message: errorMessage("onlyFullwidthCaracters"),
    });

export const firstNameValidation = () =>
  requiredValidation(i18n("firstName"))
    .regex(specialSymbolPattern, {
      message: errorMessage("cantUseSpecialSymbol"),
    })
    .regex(fullwidthCaracterPattern, {
      message: errorMessage("onlyFullwidthCaracters"),
    });

export const passwordValidation = () =>
  requiredValidation(i18n("password")).min(
    8,
    "パスワードは8文字以上で入力してください",
  );

// 電話番号ハイフンあり
export const phoneNumberValidation = () =>
  requiredValidation(i18n("phoneNumber")).regex(
    /^0[9876]0[-]?\d{4}[-]?\d{4}$/,
    "090、080、070で始まる11桁の半角数字を入力してください。",
  );

export const smsCodeValidation = () =>
  requiredValidation(i18n("smsCode")).regex(
    /^(\d{4}|\d{6})$/,
    "4桁の半角数字を入力してください。",
  );

export const sexValidation = () =>
  z.union([z.literal("female"), z.literal("male")], {
    errorMap: (issue, ctx) => {
      if (z.ZodIssueCode.invalid_literal) {
        return {
          message: "男性もしくは女性を選択してください。",
        };
      }
      return { message: ctx.defaultError };
    },
  });

export const experienceYearsValidation = () =>
  z.union([
    z.literal("less_one_year"),
    z.literal("one_to_three_year"),
    z.literal("three_to_five_year"),
    z.literal("over_five_year"),
  ]);

export const zipCodeValidation = () =>
  requiredValidation(i18n("zipCode")).regex(sevenDigitPattern, {
    message: "7桁の半角数字を入力してください。",
  });

export const prefectureValidation = () =>
  requiredValidation(i18n("prefecture")).refine((value) => {
    const prefectures = [
      "北海道",
      "青森県",
      "岩手県",
      "宮城県",
      "秋田県",
      "山形県",
      "福島県",
      "茨城県",
      "栃木県",
      "群馬県",
      "埼玉県",
      "千葉県",
      "東京都",
      "神奈川県",
      "新潟県",
      "富山県",
      "石川県",
      "福井県",
      "山梨県",
      "長野県",
      "岐阜県",
      "静岡県",
      "愛知県",
      "三重県",
      "滋賀県",
      "京都府",
      "大阪府",
      "兵庫県",
      "奈良県",
      "和歌山県",
      "鳥取県",
      "島根県",
      "岡山県",
      "広島県",
      "山口県",
      "徳島県",
      "香川県",
      "愛媛県",
      "高知県",
      "福岡県",
      "佐賀県",
      "長崎県",
      "熊本県",
      "大分県",
      "宮崎県",
      "鹿児島県",
      "沖縄県",
    ];
    return prefectures.includes(value);
  }, "47都道府県のいずれかを入力してください。");

export const cityValidation = () =>
  z
    .string({
      required_error: `${i18n("city")}を選択してください。`,
    })
    .regex(fullwidthCaracterPattern, {
      message: errorMessage("onlyFullwidthCaracters"),
    });

export const streetValidation = () =>
  requiredValidation(i18n("street")).regex(specialSymbolPattern, {
    message: errorMessage("notStreet"),
  });

export const buildingValidation = () =>
  z
    .string()
    .regex(specialSymbolPattern, {
      message: errorMessage("notBuilding"),
    })
    .or(z.string().length(0));

export const messageValidation = () =>
  requiredValidation("メッセージ").refine(
    (value) => value.trim().length > 0,
    "スペースのみの入力は不可です。",
  );

export const dateValidation = () =>
  z.date({
    required_error: "日付を入力してください。",
  });

// TODO:画像バリデーション（png,jpeg,gif）
const IMAGE_TYPES = ["image/png", "image/jpeg", "image/gif", "image/heic"];
export const imageValidation = () =>
  z
    .custom<File | File[] | undefined>()
    .refine(
      (file) =>
        typeof file === "undefined" ||
        (Array.isArray(file) && file.length > 0) ||
        file instanceof File,
      {
        message: "画像をアップロードしてください。",
      },
    )
    .refine(
      (file) =>
        typeof file === "undefined" ||
        (Array.isArray(file) && IMAGE_TYPES.includes(file[0].type)) || // 配列の最初の要素のtypeを確認
        (file instanceof File && IMAGE_TYPES.includes(file.type)),
      {
        message: ".jpgもしくは.pngもしくはgifのみ利用可能です。",
      },
    )
    .transform(async (file) => {
      if (Array.isArray(file)) {
        const image = await encodeImageToBase64(file[0]);
        return image;
      }
      if (file instanceof File) {
        const image = await encodeImageToBase64(file);
        return image;
      }
      return file;
    });

// 金融機関コード
export const bankcodeValidation = () =>
  requiredValidation(i18n("bankcode"))
    .min(4, "4桁の半角数字を入力してください。")
    .regex(/^\d{4}$/, "4桁の半角数字を入力してください。");

// 支店コード
export const branchcodeValidation = () =>
  requiredValidation(i18n("branchcode")).regex(
    /^\d{3}$/,
    "3桁の半角数字を入力してください。",
  );

export const bankNameValidation = () => requiredValidation(i18n("bankName"));

export const branchNameValidation = () =>
  requiredValidation(i18n("branchName"));

export const accountTypeValidation = () =>
  z.union([z.literal("checking"), z.literal("ordinary")], {
    errorMap: (issue, ctx) => {
      if (z.ZodIssueCode.invalid_literal) {
        return {
          message: "口座種別は普通、当座、その他のいずれかを入力してください。",
        };
      }
      return { message: ctx.defaultError };
    },
  });

export const accountNumberValidation = () =>
  requiredValidation(i18n("bankAccountNumber")).regex(sevenDigitPattern, {
    message: "7桁の半角数字を入力してください。",
  });

// TODO:口座名義人※カタカナのみ
export const accountHolderValidation = () =>
  requiredValidation(i18n("bankAccountHolder"))
    .regex(fullwidthCaracterPattern, {
      message: errorMessage("onlyFullwidthCaracters"),
    })
    .regex(/^[ァ-ヴー]+$/, {
      message: "カタカナで入力してください。",
    });

export const birthdayValidation = () =>
  z.coerce.date({
    required_error: `${i18n("birthday")}を入力してください。`,
    invalid_type_error: `${i18n("birthday")}を入力してください。`,
  });

export const changeJobsValidation = () =>
  requiredValidation(i18n("changeJobs"));

export const relationshipValidation = () =>
  requiredValidation(i18n("relationship"));

export const occupationsValidation = () =>
  requiredValidation(i18n("occupation"));

export const breakTimesValidation = () =>
  z.array(
    z.object({
      startBreakTime: z.string().optional().nullable(),
      endBreakTime: z.string().optional().nullable(),
    }),
  );

export const startWorkDayValidation = () =>
  z.date({ required_error: `${i18n("startWorkDay")}を入力してください。` });

export const endWorkDayValidation = () =>
  z.date({ required_error: `${i18n("endWorkDay")}を入力してください。` });

export const startWorkTimeValidation = () =>
  requiredValidation(i18n("startWorkTime"));

export const endWorkTimeValidation = () =>
  requiredValidation(i18n("endWorkTime"));

export const startBreakDayValidation = () =>
  z.date({ required_error: `${i18n("startBreakDay")}を入力してください。` });

export const endBreakDayValidation = () =>
  z.date({ required_error: `${i18n("endBreakDay")}を入力してください。` });

export const fixRequestReasonValidation = () =>
  requiredValidation(i18n("fixRequestReason"));

export const cancelConfilmValidation = () =>
  z
    .array(
      z.string({
        invalid_type_error: `${i18n("cancelPolicy")}を確認してください。`,
      }),
    )
    .nonempty(`${i18n("cancelPolicy")}を確認してください。`);

export const cancelReasonValidation = () =>
  requiredValidation(i18n("cancelReason"));

export const cancelMessageValidation = () =>
  requiredValidation(i18n("cancelMessage"));
