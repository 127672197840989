import { useSuspenseQuery } from "@apollo/client";
import { Modal } from "@mantine/core";
import Link from "next/link";

import {
  Button,
  Group,
  H2,
  Stack,
  Text,
  Rating,
  Span,
} from "@/components/ui/elements";

import { workerIdQuery } from "./_graphql";

type Props = {
  opened: boolean;
  close: () => void;
  average: number;
};

export function SendReviewModal({ opened, close, average }: Props) {
  const {
    data: { viewer },
  } = useSuspenseQuery(workerIdQuery);
  const workId = viewer?.id as string;
  return (
    <Modal opened={opened} onClose={close} centered zIndex={10000}>
      <Stack>
        <H2>以下でレビューが送信されました。</H2>
        <Text>
          <Group>
            <Text variant="center" fz="lg" fw="bold" pr="md">
              総合評価：{average.toFixed(2)}
            </Text>
            <Rating size="xl" value={average} readOnly fractions={4} />
          </Group>
          <br />
          レビューありがとうございました。
          <br />
          またのご応募お待ちしております！
        </Text>
        <Text>
          ケアリンクに関するアンケートを実施しております。回答いただけた方に
          <Span fw="bold">アマゾンギフト券500円分</Span>
          をプレゼントしております。
          <br />
          ぜひアンケートのご協力をお願いいたします。
          <br />
          <Span c="dimmed">
            ※プレゼントはお一人様一回までとなります。ご了承ください。
          </Span>
        </Text>
        <Link
          href={`https://docs.google.com/forms/d/e/1FAIpQLSehXtARVzZWTlOBGkAtWaqYAArCJVrPDbHVOs_2RKRGmshAyg/viewform?usp=pp_url&entry.538825183=${workId}`}
          target="_blank"
        >
          アンケートに回答する
        </Link>
        <Group justify="center">
          <Button variant="white" onClick={close}>
            閉じる
          </Button>
          <Button component={Link} href={"/work"}>
            求人一覧へ
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}
