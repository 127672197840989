import { useSuspenseQuery } from "@apollo/client";

import { Modal, Paper, Stack, Text } from "@/components/ui/elements";

import { temporaryJobWorkerReviewsQuery } from "./_graphql";

type Props = {
  opened: boolean;
  close: () => void;
  type: "good" | "bad";
};

export function ReviewListModal({ opened, close, type }: Props) {
  const {
    data: { temporaryJobWorkerReviews },
  } = useSuspenseQuery(temporaryJobWorkerReviewsQuery, {
    variables: {
      reviewType: type,
    },
  });

  // コメントが未入力のbadレビューをフィルタリング
  const filteredReviews = temporaryJobWorkerReviews.filter(
    (review) => !(type === "bad" && !review.comment),
  );

  return (
    <Modal
      title={`${type === "good" ? "goodコメント" : "badコメント"}`}
      opened={opened}
      close={close}
    >
      <Stack>
        {filteredReviews.length > 0 ? (
          filteredReviews.map((review, i) => (
            <Stack key={i}>
              <div>
                <Text fw="bold">{review.reviewerName}</Text>
                <Paper mt={5} withBorder p={10}>
                  <Text>
                    {review.comment ||
                      (type === "good"
                        ? "ありがとうございました。また機会がありましたらよろしくお願いします。"
                        : "")}
                  </Text>
                  <Text size="xs" c="dimmed" mt={5}>
                    {review.reviewedDate}
                  </Text>
                </Paper>
              </div>
            </Stack>
          ))
        ) : (
          <Text>コメントはまだありません。</Text>
        )}
      </Stack>
    </Modal>
  );
}
