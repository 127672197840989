import { graphql } from "@/gql/__generated__";

export const reviewTemporaryJobOfferMutation = graphql(`
  mutation ReviewTemporaryJobOffer($input: ReviewClientOfficeInput!) {
    reviewClientOffice(input: $input) {
      temporaryJobOffer {
        id
      }
    }
  }
`);

export const addFavoriteMutaiton = graphql(`
  mutation AddFavorite($input: AddFavoriteInput!) {
    addFavorite(input: $input) {
      temporaryJobOffer {
        id
        favorited
        temporaryJobOffer {
          id
        }
      }
    }
  }
`);

export const removeFavoriteMutaiton = graphql(`
  mutation RemoveFavorite($input: RemoveFavoriteInput!) {
    removeFavorite(input: $input) {
      temporaryJobOffer {
        id
        favorited
        temporaryJobOffer {
          id
        }
      }
    }
  }
`);
