import { format } from "date-fns";

import { MessageWithLinks } from "./MessageWithLinks";

type Props = {
  id: string;
  body: string;
  senderType: string;
  sentAt: string;
  isReceivedMessage: boolean;
};

export function MessageItem({
  id,
  body,
  senderType,
  sentAt,
  isReceivedMessage,
}: Props) {
  return (
    <div
      key={id}
      style={{
        marginBottom: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          padding: "10px",
          backgroundColor: isReceivedMessage ? "#e0e0e0" : "#aedcff",
          borderRadius: "5px",
          alignSelf: isReceivedMessage ? "flex-start" : "flex-end",
          wordWrap: "break-word",
          wordBreak: "break-all",
          whiteSpace: "pre-wrap",
        }}
      >
        <MessageWithLinks body={body} />
      </div>
      <div
        style={{
          fontSize: "0.8em",
          color: "#555",
          alignSelf: isReceivedMessage ? "flex-start" : "flex-end",
          marginTop: "5px",
        }}
      >
        {format(new Date(sentAt), "yyyy/MM/dd HH:mm")}
      </div>
    </div>
  );
}
