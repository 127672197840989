"use client";
import { useSuspenseQuery } from "@apollo/client";
import Link from "next/link";
import { usePathname } from "next/navigation";

import { Button, LineIcon } from "@/components/ui/elements";

import { isLineLinkingQuery } from "./_graphql";

export function LineButton() {
  const pathname = usePathname();
  const pathQuery = `?next=${pathname}`;
  const redirectUri = process.env
    .NEXT_PUBLIC_LINE_LINKING_REDIRECT_URL as string;
  const channelId = process.env.NEXT_PUBLIC_LINE_CHANNEL_ID as string;
  // const sercretKey = process.env.NEXT_PUBLIC_LINE_CHANNEL_SRECRET_KEY as string;
  const encodeUri = encodeURIComponent(redirectUri + pathQuery);
  const {
    data: { viewer },
  } = useSuspenseQuery(isLineLinkingQuery);
  const isLineLinking = Boolean(viewer?.hasLineAccount);
  return (
    <Button
      component={Link}
      href={`https://access.line.me/oauth2/v2.1/authorize?response_type=code&scope=profile%20openid&state=123&client_id=${channelId}&redirect_uri=${encodeUri}&prompt=consent&bot_prompt=normal`}
      radius="sm"
      leftSection={<LineIcon size="1rem" />}
      // variant="outline"
      color="line.0"
      size="xs"
      disabled={isLineLinking}
    >
      {isLineLinking ? "連携済み" : "LINE連携"}
    </Button>
  );
}
