import { useEffect } from "react";

import { GraphQLFormattedError } from "graphql";
import Link from "next/link";
import { useRouter } from "next/navigation";

import { logError } from "@/libs/loging/slack";

import {
  Button,
  Center,
  Container,
  H2,
  Stack,
  Text,
} from "@/components/ui/elements";

import { MaintenanceError } from "./Maintenance";

type Props = {
  graphQLErrors: readonly GraphQLFormattedError[];
  reset: () => void;
  notFoundHandling?: {
    text: string;
    link: string;
  };
};
export function GraphqlErrors({
  graphQLErrors,
  reset,
  notFoundHandling,
}: Props) {
  const router = useRouter();
  const isUnAuthorized = graphQLErrors.some(
    (e) => e.extensions?.code === "UNAUTHORIZED_ERROR",
  );
  const isNotFound = graphQLErrors.some(
    (e) => e.extensions?.code === "NOT_FOUND_ERROR",
  );
  const isForbidden = graphQLErrors.some(
    (e) => e.extensions?.code === "FORBIDDEN_ERROR",
  );
  const isPreconditionFailed = graphQLErrors.some(
    (e) => e.extensions?.code === "PRECONDITION_FAILED_ERROR",
  );
  const isServerError = graphQLErrors.some(
    (e) => e.extensions?.code === "SERVER_ERROR",
  );
  const isUnProcessableContent = graphQLErrors.some(
    (e) => e.extensions?.code === "UNPROCESSABLE_CONTENT_ERROR",
  );
  const isMaintenance = graphQLErrors.some(
    (e) => e.extensions?.code === "SERVICE_UNAVAILABLE",
  );
  const errorMessage = graphQLErrors?.[0]?.message || "エラーメッセージなし";
  useEffect(() => {
    if (isUnAuthorized) return;
    logError({
      errorHandling: "graphqlErrors",
      message: errorMessage,
      type: (graphQLErrors?.[0]?.extensions?.code as string) || "エラー名なし",
      url: window.location.href,
      userAgent: window.navigator.userAgent,
      code: (graphQLErrors?.[0]?.extensions?.code as string) || "エラー名なし",
      stack: JSON.stringify(graphQLErrors) || "スタックトレースなし",
    });
  }, [graphQLErrors, isUnAuthorized, errorMessage]);
  if (isMaintenance) {
    return <MaintenanceError />;
  }
  if (isUnAuthorized) {
    const handleRedirect = async () => {
      await setTimeout(() => router.replace("/signup"), 2000); // 2秒後にリダイレクト
    };
    if (typeof window !== "undefined") {
      console.log("delete");
      document.cookie = "auth_token=; max-age=0; path=/;";
    }
    handleRedirect();
    return (
      <Container fluid bg="gray.0" mih={"100dvh"}>
        <Center w={"100%"} h={"100dvh"}>
          <Stack>
            <H2>再ログインが必要です。</H2>
            <Text>
              サインアップ画面へリダイレクトしています。しばらくお待ちください。
              <br />
              リダイレクトされない場合はログイン画面へ戻るから再度ログインしてください。
            </Text>
            <Button component={Link} href={"/signup"}>
              ログイン画面へ戻る
            </Button>
          </Stack>
        </Center>
      </Container>
    );
  }
  if (isNotFound) {
    if (notFoundHandling) {
      const { link, text } = notFoundHandling;
      return (
        <Container fluid bg="gray.0" mih={"100dvh"}>
          <Center w={"100%"} h={"100dvh"}>
            <Stack>
              <H2>{errorMessage}</H2>
              <Button component={Link} href={link}>
                {text}
              </Button>
            </Stack>
          </Center>
        </Container>
      );
    }
    return (
      <Container fluid bg="gray.0" mih={"100dvh"}>
        <Center w={"100%"} h={"100dvh"}>
          <Stack>
            <H2>{errorMessage}</H2>
            <Button component={Link} href={"/work"}>
              求人一覧へ戻る
            </Button>
          </Stack>
        </Center>
      </Container>
    );
  }
  return (
    <Container fluid bg="gray.0" mih={"100dvh"}>
      <Center w={"100%"} h={"100dvh"}>
        <Stack>
          <H2>データ取得中にエラーが発生しました。</H2>
          <Text>
            時間をおいて再試行してください。解決しない場合は管理者にお問い合わせください。
            <br />
            以下のエラーが発生しています。
          </Text>
          {graphQLErrors.map((e, i) => (
            <Text key={i}>
              エラーメッセージ：{e.message || "エラーメッセージなし"}
            </Text>
          ))}
          <Button onClick={() => reset()}>リセット</Button>
          <Button component={Link} href={"/signup"}>
            ログイン画面へ戻る
          </Button>
        </Stack>
      </Center>
    </Container>
  );
}
