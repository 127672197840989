import { graphql } from "@/gql/__generated__";

export const myPageQuery = graphql(`
  query MyPage {
    profile {
      id
      birthday
      firstName
      imageId
      imageUrl
      lastName
      nearestStationName
      authenticated
      occupation {
        id
        name
      }
      sex
    }
    usageStatus {
      absenceCount
      confirmedWorkConditionRate
      goodRate
      totalWageAmount
      totalWorkingHour
      workedCount
      goodReviewCount
      badReviewCount
    }
    temporaryJobWorker {
      id
      points
    }
    viewer {
      id
      isSuspended
      suspensionStartDateAt
      suspensionEndDateAt
    }
  }
`);

export const updateProfileMutation = graphql(`
  mutation UpdateResumeProfile($input: UpdateResumeProfileInput!) {
    updateResumeProfile(input: $input) {
      temporaryJobWorker {
        id
        profile {
          id
          birthday
          firstName
          imageId
          imageUrl
          lastName
          sex
        }
        caregiverExperience {
          employmentPeriod
          id
          businessGenres {
            id
            name
          }
        }
      }
    }
  }
`);

export const occupationQuery = graphql(`
  query Occupations {
    occupations {
      id
      name
    }
  }
`);

export const jobCategoriesQuery = graphql(`
  query JobCategories {
    jobCategories {
      id
      name
    }
  }
`);

export const logoutMutation = graphql(`
  mutation Logout($input: LogoutInput!) {
    logout(input: $input) {
      temporaryJobWorker {
        id
      }
    }
  }
`);

export const businessGenresQuery = graphql(`
  query BusinessGenres {
    businessGenres {
      id
      name
    }
  }
`);

export const temporaryJobWorkerSmsExcludeQuery = graphql(`
  query TemporaryJobWorkerSmsExclude {
    temporaryJobWorker {
      id
      smsExclude
    }
  }
`);

export const temporaryJobWorkerReviewsQuery = graphql(`
  query TemporaryJobWorkerReviews($reviewType: String) {
    temporaryJobWorkerReviews(reviewType: $reviewType) {
      id
      evaluation
      reviewerName
      reviewedDate
      comment
    }
  }
`);
