import { useEffect } from "react";

import { ServerError, ServerParseError } from "@apollo/client";
import Link from "next/link";
import { useRouter } from "next/navigation";

import { logError } from "@/libs/loging/slack";

import {
  Button,
  Center,
  Container,
  H2,
  Stack,
  Text,
} from "@/components/ui/elements";

type Props = {
  networkError: Error | ServerParseError | ServerError;
};

export function NetworkError({ networkError }: Props) {
  const router = useRouter();
  const statusCode = (networkError as ServerError).statusCode;
  useEffect(() => {
    logError({
      errorHandling: "networkError",
      message: networkError.message || "エラーメッセージなし",
      type: networkError.name || "エラー名なし",
      url: window.location.href,
      userAgent: window.navigator.userAgent,
      stack: networkError.stack || "スタックトレースなし",
      code: statusCode ? statusCode.toString() : "不明",
    });
  }, [networkError]);
  return (
    <Container fluid bg="gray.0" mih={"100dvh"}>
      <Center w={"100%"} h={"100dvh"}>
        <Stack>
          <H2>ネットワークでエラーが発生しました。</H2>
          <Text>
            通信環境をご確認ください。解決しない場合は管理者にお問い合わせください。
            <br />
            以下のエラーが発生しています。
          </Text>
          <Text>
            エラー名：{networkError.name || "エラー名なし"}
            エラーメッセージ：{networkError.message || "エラーメッセージなし"}
          </Text>
          <Button onClick={router.back}>戻る</Button>
          <Button component={Link} href={"/signup"}>
            ログイン画面へ戻る
          </Button>
        </Stack>
      </Center>
    </Container>
  );
}
