import { graphql } from "@/gql/__generated__";

export const AddAdminMessageMutation = graphql(`
  mutation AddAdminMessage($input: AddAdminMessageInput!) {
    addAdminMessage(input: $input) {
      message {
        body
      }
    }
  }
`);

export const AdminMessagesQuery = graphql(`
  query AdminMessages {
    adminMessages {
      id
      body
      sentAt
      senderType
    }
  }
`);

export const hasNewMessagesQuery = graphql(`
  query HasNewMessage {
    viewer {
      id
      hasNewMessages
    }
  }
`);
