import Link from "next/link";

import { AlertField } from "@/components/ui/parts";

export function SmsVerficationAlert() {
  return (
    <AlertField
      type="notice"
      maw={500}
      title="info"
      style={{ whiteSpace: "pre-wrap" }}
    >
      ※SMSで4ケタの確認コードを配信します。
      <br />
      ※確認コードは発行後24時間で使用できなくなります。
      <br />
      ※SMSが届かない場合は以下をご確認ください。
      <br />
      ・電話番号の入力ミス
      <br />
      <Link
        target="_blank"
        href="https://www.notion.so/2021-07-it-support/Android-23caa0a6bac24fada393eea3245901f5"
      >
        ・SMS受信設定（Android）
      </Link>
      <br />
      以上をご確認の上、解決できない場合は管理者にご連絡ください。
      <br />
      <br />
      フリーダイヤル：
      <a type="tel" href="tel:0120-327-002">
        0120-327-002
      </a>
      <br />
      問合せメールアドレス：
      <a href="mailto:support@care-link.works">support@care-link.works</a>
    </AlertField>
  );
}
