import { useMutation } from "@apollo/client";
import { ActionIcon } from "@mantine/core";

import { HeartIcon, HeartOutlineIcon } from "@/components/ui/elements";

import { infoMessage } from "@/i18n";
import { showNotification } from "@/util";

import { addFavoriteMutaiton, removeFavoriteMutaiton } from "../_graphql";
import classes from "./_css/WorkFavorite.module.css";

type Props = {
  isFavorite: boolean | null;
  temporaryJobOfferId: string;
};

export function WorkFavorite(props: Props) {
  const { isFavorite, temporaryJobOfferId } = props;
  const [addMutation, { loading: addLoading }] = useMutation(
    addFavoriteMutaiton,
    {
      refetchQueries: ["Bookmarks", "JobOfferFeed"],
    },
  );
  const [removeMutation, { loading: removeLoading }] = useMutation(
    removeFavoriteMutaiton,
    {
      refetchQueries: ["Bookmarks", "JobOfferFeed"],
    },
  );

  const handleAddFavorite = async () => {
    await addMutation({
      variables: {
        input: {
          temporaryJobOfferId: temporaryJobOfferId,
        },
      },
      onError: (error) =>
        showNotification({
          type: "error",
          message: error.message,
        }),
      onCompleted: (data) => {
        const res = data.addFavorite;
        if (!res) return;
        showNotification({
          type: "info",
          message: infoMessage("addFavorite"),
        });
      },
    });
  };
  const handleremoveFavorite = async () => {
    await removeMutation({
      variables: {
        input: {
          temporaryJobOfferId: temporaryJobOfferId,
        },
      },
      onError: (error) =>
        showNotification({
          type: "error",
          message: error.message,
        }),
      onCompleted: (data) => {
        const res = data.removeFavorite;
        if (!res) return;
        showNotification({
          type: "info",
          message: infoMessage("removeFavorite"),
        });
      },
    });
  };
  const isLoading = addLoading || removeLoading;
  return (
    <ActionIcon
      className={classes.icon}
      variant="subtle"
      radius="md"
      loading={isLoading}
      color={isFavorite ? "red" : "gray"}
      onClick={isFavorite ? handleremoveFavorite : handleAddFavorite}
    >
      {isFavorite ? (
        <HeartIcon size={"2rem"} />
      ) : (
        <HeartOutlineIcon size={"2rem"} />
      )}
    </ActionIcon>
  );
}
