import { Badge } from "@/components/ui/elements";

type Props = {
  businessCategoryName: string;
};
export function JobTypeBadge({ businessCategoryName }: Props) {
  const defaultCategory = {
    name: "介護",
    color: "blue",
  };
  const businessCategoryObj = [
    {
      name: "介護",
      color: "blue",
    },
    { name: "福祉", color: "orange" },
    { name: "訪問", color: "grape" },
  ];
  const businessCategory =
    businessCategoryObj.find(
      (category) => category.name === businessCategoryName,
    ) || defaultCategory;
  return (
    <Badge variant="outline" size="sm" color={businessCategory.color}>
      {businessCategory.name}
    </Badge>
  );
}
