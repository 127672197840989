import Image from "next/image";

import {
  TimeFiveIcon,
  Chip,
  Group,
  Text,
  Card,
  H2,
} from "@/components/ui/elements";
import { IconLabel } from "@/components/ui/parts";

import { PastEmployedTemporaryJobOfferFeedItemFieldsFragment } from "@/gql/__generated__/graphql";

import classes from "./_css/PastWorkCard.module.css";

type Props = {
  jobOffer: PastEmployedTemporaryJobOfferFeedItemFieldsFragment;
};

export function PastWorkCard({
  jobOffer: {
    id,
    temporaryJobOffer: { title },
    tags,
    officeName,
    imageUrl,
    perHourWage,
    workDate,
  },
}: Props) {
  const noimage =
    "https://placehold.jp/30/dd6699/ffffff/300x150.png?text=placeholder+image";

  return (
    <div className={classes.container}>
      <Card withBorder shadow="lg" radius="md" p="md" className={classes.card}>
        <Card.Section className={classes.image} h={250}>
          <Image src={imageUrl ? imageUrl : noimage} alt={title} fill />
        </Card.Section>

        <Card.Section className={classes.section} mt="sm">
          <Group justify="space-between">
            <H2>{title}</H2>
          </Group>
          {tags &&
            tags.map((w, i) => <Chip key={i} py={5} size="md" label={w} />)}
          <Group justify="space-between">
            <Group>
              <IconLabel
                icon={<TimeFiveIcon color="teal" />}
                label={`${workDate}`}
                labelProps={{ fz: "sm" }}
              />
            </Group>
            <div>
              <Text fz="lg" fw={800}>
                {perHourWage}
              </Text>
              <Text fz="sm">（交通費800円込み）</Text>
            </div>
          </Group>
          <Group pt={5} justify="between">
            <Text fz="lg" fw={800} c="teal.8">
              {officeName}
            </Text>
          </Group>
        </Card.Section>
      </Card>
    </div>
  );
}
