"use client";
import { useState } from "react";

import axios from "axios";

type Zip = {
  status: number;
  message: string | null;
  results:
    | {
        address1: string;
        address2: string;
        address3: string;
        kana1: string;
        kana2: string;
        kana3: string;
        prefcode: string;
        zipcode: string;
      }[]
    | null;
};

type Address = {
  address: {
    prefecture: string;
    city: string;
    street: string;
  };
};

export function useAddressByZipCode() {
  const [loading, setLoading] = useState(false);
  const initAddress = {
    address: {
      prefecture: "",
      city: "",
      street: "",
    },
  };
  const getAddress = async (zipcode: string): Promise<Address> => {
    if (zipcode === "") return initAddress;
    setLoading(true);
    const res = await axios.get<Zip>(
      "https://zipcloud.ibsnet.co.jp/api/search",
      {
        params: { zipcode: zipcode },
      },
    );
    setLoading(false);
    if (res.data.status === 200) {
      const results = res.data.results;
      if (!results) return initAddress;
      return {
        address: {
          prefecture: results[0].address1,
          city: results[0].address2,
          street: results[0].address3,
        },
      };
    }
    return initAddress;
  };
  return {
    getAddress,
    loading,
  };
}
