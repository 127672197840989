import { useEffect } from "react";

import { ApolloError } from "@apollo/client";
import Link from "next/link";

import { logError } from "@/libs/loging/slack";

import {
  Button,
  Center,
  Container,
  H2,
  Stack,
  Text,
} from "@/components/ui/elements";

type Props = {
  error: ApolloError;
};

export function GeneralError({ error }: Props) {
  useEffect(() => {
    logError({
      errorHandling: "generalError",
      message: error.message || "エラーメッセージなし",
      type: error.name || "エラー名なし",
      url: window.location.href,
      userAgent: window.navigator.userAgent,
      stack: error.stack || "スタックトレースなし",
    });
  }, [error]);

  return (
    <Container fluid bg="gray.0" mih={"100dvh"}>
      <Center>
        <Stack>
          <H2>エラーが発生しました。</H2>
          <Text>解決しない場合は管理者にお問い合わせください。</Text>
          <Button component={Link} href={"signup"}>
            ログイン画面へ戻る
          </Button>
        </Stack>
      </Center>
    </Container>
  );
}
