import React from "react";

type Props = {
  body: string;
};

export function MessageWithLinks({ body }: Props) {
  const displayMessage = (text: string) => {
    const parts = text.split(/(https?:\/\/[^\s]+)/g);
    return parts.map((part, index) =>
      /^https?:\/\//.test(part) ? (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      ) : (
        part
      ),
    );
  };

  return (
    <div>
      <div>{displayMessage(body)}</div>
    </div>
  );
}
