import { useDisclosure } from "@mantine/hooks";

import { Divider, H2, H3, Modal, Stack } from "@/components/ui/elements";

export function CancelModal() {
  const [opened, { toggle, close }] = useDisclosure();
  return (
    <Modal opened={opened} close={close}>
      <Stack>
        <H2>仕事のキャンセル</H2>
        <Divider />
        <H3>キャンセルポリシー確認</H3>
      </Stack>
    </Modal>
  );
}
