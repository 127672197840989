import { graphql } from "@/gql/__generated__";

export const isLineLinkingQuery = graphql(`
  query IsLineLinking {
    viewer {
      id
      hasLineAccount
    }
  }
`);
