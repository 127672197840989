import { useState, useEffect, useCallback, useMemo } from "react";

import { useInterval } from "@mantine/hooks";

type TimeState = {
  timeInSeconds: number;
  hours: number;
  minutes: number;
  seconds: number;
  isExpired: boolean;
  isLastDay: boolean;
};

export function useRemainingTime(
  remainingDays: string | null,
  remainingHoursAndMinutes: string,
) {
  const initialState = useMemo((): TimeState => {
    try {
      const [hours, minutes] = remainingHoursAndMinutes.split(":").map(Number);

      if (isNaN(hours) || isNaN(minutes)) {
        throw new Error("Invalid time format");
      }

      const timeInSeconds = hours * 60 * 60 + minutes * 60;

      return {
        timeInSeconds,
        hours,
        minutes,
        seconds: 0,
        isExpired: timeInSeconds <= 0 || remainingHoursAndMinutes === "00:00",
        isLastDay: remainingDays === "0",
      };
    } catch (error) {
      console.error("Error parsing time:", error);
      return {
        timeInSeconds: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        isExpired: true,
        isLastDay: false,
      };
    }
  }, [remainingDays, remainingHoursAndMinutes]);
  const [state, setState] = useState<TimeState>(initialState);

  const updateTime = useCallback(() => {
    setState((prevState) => {
      if (prevState.isExpired) return prevState; // すでに期限切れの場合は更新しない

      const newTimeInSeconds = prevState.timeInSeconds - 1;
      const isExpired = newTimeInSeconds <= 0;
      //console.log(newTimeInSeconds);
      return {
        timeInSeconds: isExpired ? 0 : newTimeInSeconds,
        hours: Math.floor(newTimeInSeconds / 3600),
        minutes: Math.floor((newTimeInSeconds % 3600) / 60),
        seconds: newTimeInSeconds % 60,
        isExpired,
        isLastDay: remainingDays === "0",
      };
    });
  }, [remainingDays]);

  const interval = useInterval(updateTime, 1000);

  useEffect(() => {
    let mounted = true;
    const startInterval = () => {
      if (!mounted) return;
      if (state.isExpired || state.timeInSeconds <= 0) {
        interval.stop();
      } else {
        interval.start();
      }
    };
    startInterval();
    // クリーンアップ関数
    return () => {
      mounted = false;
      interval.stop();
    };
  }, [interval, state.isExpired, state.timeInSeconds]);

  // エラー状態のチェック
  if (state.timeInSeconds < 0 || isNaN(state.timeInSeconds)) {
    return {
      ...initialState,
      isExpired: true,
    };
  }

  return state;
}
