import { useEffect, useRef, useState } from "react";

import { Loader, Select } from "@/components/ui/elements";

import { isErrorResponse, useGetBanks } from "@/hooks/bank";

type Props = {
  defaultbank: string | undefined;
  defaultbankName: string | undefined;
  getBankCode: (code: string) => void;
  getBankName: (name: string) => void;
};

type BankItem = {
  value: string;
  label: string;
};

export function BankNameInput({
  defaultbank,
  defaultbankName,
  getBankCode,
  getBankName,
}: Props) {
  const [bankList, setBankList] = useState<BankItem[]>([]);
  const mountedRef = useRef(false);
  const timeoutRef = useRef<number | null>(null);
  const cooldownRef = useRef(false);
  const cancel = () => window.clearTimeout(timeoutRef.current!);
  const [searchValue, onSearchChange] = useState(
    defaultbankName ? defaultbankName : "",
  );
  const { getFilterBank, loading } = useGetBanks();
  function filterBank(query: string) {
    getFilterBank(query)
      .then((result) => {
        if (!result) return;
        if (isErrorResponse(result)) return setBankList([]);
        if (!result.length) return setBankList([]);
        const list = result.map((item) => {
          return { value: item.code, label: item.name };
        });
        setBankList(list);
      })
      .catch(() => {
        return setBankList([]);
      });
  }

  useEffect(() => {
    mountedRef.current = true;
    return cancel;
  }, []);

  useEffect(() => {
    if (mountedRef.current) {
      if (!cooldownRef.current) {
        cooldownRef.current = true;
        filterBank(searchValue);
      } else {
        cancel();
        timeoutRef.current = window.setTimeout(() => {
          cooldownRef.current = false;
          filterBank(searchValue);
        }, 200);
      }
    }
  }, [searchValue]);

  return (
    <Select
      defaultValue={defaultbank}
      withAsterisk
      data={bankList}
      searchable
      filter={() => bankList}
      onSearchChange={onSearchChange}
      searchValue={searchValue}
      placeholder=""
      description="金融機関名を入力し、入力後に表示される候補の中から選択してください。"
      maw={200}
      label="金融機関名"
      nothingFoundMessage={loading ? <Loader /> : "金融機関が見つかりません。"}
      limit={20}
      onChange={(value: string) => getBankCode(value)}
      onBlur={(e) => getBankName(e.target.value)}
      rightSection={loading ? <Loader size="xs" /> : <div></div>}
    />
  );
}
