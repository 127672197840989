"use client";
import { useCallback, useState } from "react";

import axios from "axios";

import { errorMessage } from "@/i18n";
import { convertToHiragana } from "@/util/hiragana";
import { showNotification } from "@/util/userNotifications";

export type Banks = {
  data: Bank[];
  size: number;
  limit: number;
};

export type Bank = {
  code: string;
  name: string;
  halfWidthKana: string;
  fullWidthKana: string;
  hiragana: string;
  businessTypeCode: string;
  businessType: string;
};

type Branch = {
  code: string;
  name: string;
  halfWidthKana: string;
  fullWidthKana: string;
  hiragana: string;
}[];

type ErrorResponse = {
  httpStatusCode: number;
  code: string;
  message: string;
  validationErrors: {
    message: string;
    paramName: string;
    invalidValue: string;
  }[];
};

export function isErrorResponse(
  data: Banks | Bank | Branch | ErrorResponse,
): data is ErrorResponse {
  return "httpStatusCode" in data;
}

export function useGetBankResourcesByCode() {
  const [loading, setLoading] = useState(false);
  const getBank = async ({
    bankcode,
  }: {
    bankcode: string;
  }): Promise<Bank | void> => {
    if (bankcode === "") return;
    setLoading(true);
    const res = await axios
      .get<Bank | ErrorResponse>(
        `https://apis.bankcode-jp.com/v2/banks/${bankcode}`,
        {
          headers: { apikey: process.env.NEXT_PUBLIC_BANKCODE_API_KEY },
        },
      )
      .then((res) => {
        const { data, status } = res;
        if (isErrorResponse(data))
          return showNotification({
            type: "caution",
            message: errorMessage("notFoundBankCode"),
          });
        return data;
      })
      .catch((error) => {
        return showNotification({
          type: "caution",
          message: errorMessage("notFoundBankCode"),
        });
      });
    setLoading(false);
    return res;
  };

  return { getBank, loading };
}

export function useGetBankBrancheResourcesByCode() {
  const [loading, setLoading] = useState(false);
  const getBankBranche = async ({
    bankcode,
    branchcode,
  }: {
    bankcode: string;
    branchcode: string;
  }): Promise<Branch | void> => {
    if (bankcode === "") return;
    if (branchcode === "") return;
    setLoading(true);
    const res = await axios
      .get<Branch | ErrorResponse>(
        `https://apis.bankcode-jp.com/v2/banks/${bankcode}/branches/${branchcode}`,
        {
          headers: { apikey: process.env.NEXT_PUBLIC_BANKCODE_API_KEY },
        },
      )
      .then((res) => {
        const { data, status } = res;
        if (isErrorResponse(data))
          return showNotification({
            type: "caution",
            message: errorMessage("notFoundBranchCode"),
          });

        return data;
      })
      .catch((error) => {
        return showNotification({
          type: "caution",
          message: errorMessage("notFoundBranchCode"),
        });
      });
    setLoading(false);
    return res;
  };
  return { getBankBranche, loading };
}

export const useGetBanks = () => {
  const [loading, setLoading] = useState(false);
  const getFilterBank = useCallback(
    async (input: string): Promise<Bank[] | ErrorResponse | undefined> => {
      if (input === "") return undefined;

      setLoading(true);
      const hiraganaInput = await convertToHiragana(input);
      const filter = `filter=(name==${input}*,hiragana==${hiraganaInput}*)`;
      const res = await axios
        .get<Banks | ErrorResponse>(
          `https://apis.bankcode-jp.com/v2/banks/?limit=20&fields=code,name&sort=hiragana&${filter}`,
          {
            headers: { apikey: process.env.NEXT_PUBLIC_BANKCODE_API_KEY },
          },
        )
        .then((res) => {
          const { data, status } = res;
          if (isErrorResponse(data)) {
            showNotification({
              type: "caution",
              message: errorMessage("notFoundBankCode"),
            });
            return undefined;
          }

          return data;
        })
        .catch((error) => {
          showNotification({
            type: "caution",
            message: errorMessage("notFoundBankCode"),
          });
          return undefined;
        });
      setLoading(false);
      return res?.data;
    },
    [],
  );

  return { getFilterBank, loading };
};

export const useGetBankBranches = () => {
  const [loading, setLoading] = useState(false);
  const getBranches = useCallback(
    async (
      bankCode: string,
      input: string,
    ): Promise<Branch | ErrorResponse | undefined> => {
      if (input === "") return undefined;
      setLoading(true);

      const hiraganaInput = await convertToHiragana(input);
      const filter = `filter=(name==${input}*,hiragana==${hiraganaInput}*)`;
      const res = await axios
        .get<Banks | ErrorResponse>(
          `https://apis.bankcode-jp.com/v2/banks/${bankCode}/branches?limit=20&fields=code,name&sort=hiragana&${filter}`,
          {
            headers: { apikey: process.env.NEXT_PUBLIC_BANKCODE_API_KEY },
          },
        )
        .then((res) => {
          const { data, status } = res;
          if (isErrorResponse(data)) {
            showNotification({
              type: "caution",
              message: errorMessage("notFoundBankCode"),
            });
            return undefined;
          }

          return data;
        })
        .catch((error) => {
          showNotification({
            type: "caution",
            message: errorMessage("notFoundBankCode"),
          });
          return undefined;
        });
      setLoading(false);
      return res?.data;
    },
    [],
  );

  return { getBranches, loading };
};
