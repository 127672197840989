import { Table } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import { H2, Modal, Span, Stack, Text } from "@/components/ui/elements";

type Props = {
  opened: boolean;
  close: () => void;
};

export function WorkerPointModal({ opened, close }: Props) {
  const isMobile = useMediaQuery("(max-width: 50em)");

  const penaltyBody = [
    { cancelTime: "168時間(7日)以上前", penaltyPoint: "0pt" },
    { cancelTime: "~168時間(7日)前まで", penaltyPoint: "-1pt" },
    { cancelTime: "~120時間(5日)前まで", penaltyPoint: "-2pt" },
    { cancelTime: "~96時間(4日)前まで", penaltyPoint: "-3pt" },
    { cancelTime: "~72時間（3日）前まで", penaltyPoint: "-4pt" },
    { cancelTime: "~48時間（2日）前まで", penaltyPoint: "-5pt" },
    { cancelTime: "~36時間前まで", penaltyPoint: "-6pt" },
    { cancelTime: "~24時間前まで", penaltyPoint: "-8pt" },
    { cancelTime: "~12時間前まで", penaltyPoint: "-10pt" },
    { cancelTime: "遅刻", penaltyPoint: "-3pt" },
  ];
  const penaltyRows = penaltyBody.map((body, i) => (
    <Table.Tr key={i}>
      <Table.Td w={170} maw={170}>
        {body.cancelTime}
      </Table.Td>
      <Table.Td>
        <Text c="red" fw="bold" key={i} ta="center">
          {body.penaltyPoint}
        </Text>
      </Table.Td>
    </Table.Tr>
  ));

  const incentiveBody = [
    { applyTime: "~120時間(5日)以上前", incentivePoint: "+3pt" },
    { applyTime: "~96時間(4日)前", incentivePoint: "+2pt" },
    { applyTime: "~72時間(3日)前", incentivePoint: "+1pt" },
    { applyTime: "勤務後のレビュー投稿", incentivePoint: "+1pt" },
  ];

  const incentiveRows = incentiveBody.map((body, i) => (
    <Table.Tr key={i}>
      <Table.Td w={170} maw={170}>
        {body.applyTime}
      </Table.Td>
      <Table.Td>
        <Text c="blue" fw="bold" key={i} ta="center">
          {body.incentivePoint}
        </Text>
      </Table.Td>
    </Table.Tr>
  ));

  const applicationLimitData = [
    { points: "11pt以上", noHistory: "20件", withHistory: "6件" },
    { points: "6pt-10pt", noHistory: "15件", withHistory: "4件" },
    { points: "0pt-5pt", noHistory: "5件", withHistory: "2件" },
  ];

  const applicationLimitRows = applicationLimitData.map((data, i) => (
    <Table.Tr key={i}>
      <Table.Td>{data.points}</Table.Td>
      <Table.Td ta="center">{data.noHistory}</Table.Td>
      <Table.Td ta="center">{data.withHistory}</Table.Td>
    </Table.Tr>
  ));

  return (
    <Modal opened={opened} close={close} fullScreen={isMobile}>
      <Stack py="md">
        <div>
          <H2>【持ち点制度とは】</H2>
          <Text mt={5}>
            <Span fw="bold">ご登録時に10ポイント(pt)が付与されます。</Span>
            <br />
            持ち点の最大値は15ptで、
            <Span fw="bold" c="red">
              0pt以下になると利用停止となり、30日間ケアリンクを利用できなくなります。
            </Span>
            <br />
            <Span fw="bold">
              また持ち点によって一度に応募できる応募数の制限がされます。
            </Span>
            <br />
            以下の条件でポイントが減少します。
            <br />
            ---
            <br />
            <Span fw="bold">・応募した求人のキャンセル</Span>
            <br />
            <Span fw="bold">・勤務に遅刻</Span>
            <br />
            <Span fw="bold">・無断欠勤(１回でアカウント停止)</Span>
            <br />
            ---
            <br />
            また、
            <Span fw="bold" c="blue">
              勤務後に事業所のレビュー投稿や早期応募により、減少した持ち点を回復することができます。
            </Span>
          </Text>
        </div>
        <div>
          <H2>【利用停止について】</H2>
          <Text mt={5}>
            １回目の利用停止期間中は
            <Span fw="bold">
              一時的にケアリンクのご利用ができなくなります。
            </Span>
            <br />
            <Span fw="bold">
              30日間を経過すると利用停止が解除され、その後は持ち点が5ptから開始となります。
            </Span>
            <br />
            <Span fw="bold" c="red">
              再度利用停止になった場合は、強制退会となり今後ケアリンクのご利用ができなくなります。
            </Span>
          </Text>
        </div>

        <H2>【ペナルティルール】</H2>
        <Table title="ペナルティポイント" withTableBorder withColumnBorders>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>キャンセル時間*</Table.Th>
              <Table.Th w={80} maw={80} ta="center">
                ペナルティ
                <br />
                ポイント
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{penaltyRows}</Table.Tbody>
        </Table>
        <Text c="dimmed">＊ 勤務開始時間を基準としたキャンセル時間。</Text>
        <H2>【加算ルール】</H2>
        <Table title="加算ポイント" withTableBorder withColumnBorders>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>求人応募時間</Table.Th>
              <Table.Th w={80} maw={80} ta="center">
                加算ポイント
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{incentiveRows}</Table.Tbody>
        </Table>
        <Text c="dimmed">
          ＊
          キャンセルまたは遅刻した場合は加算ポイントは付与されず、ペナルティポイントが適用されます。
        </Text>
        <H2>【応募件数制限】</H2>
        <Table title="応募制限" withTableBorder withColumnBorders>
          <Table.Thead>
            <Table.Tr>
              <Table.Th rowSpan={2}>持ち点</Table.Th>
              <Table.Th colSpan={2} ta="center">
                利用停止履歴
              </Table.Th>
            </Table.Tr>
            <Table.Tr>
              <Table.Th w={100} maw={100} ta="center">
                なし
              </Table.Th>
              <Table.Th w={100} maw={100} ta="center">
                あり
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{applicationLimitRows}</Table.Tbody>
        </Table>
        <Text c="dimmed">＊ 一度に応募できる件数の上限です。</Text>
      </Stack>
    </Modal>
  );
}
