import { Badge, TimeFiveIcon } from "@/components/ui/elements";

import { useRemainingTime } from "@/hooks/remainingTime";

import classes from "./_css/WorkTimeRemaining.module.css";

type Props = {
  remainingDays: string | null;
  remainingHoursAndMinutes: string;
  fulfilled: boolean;
};

export function WorkTimeRemaining({
  remainingDays,
  remainingHoursAndMinutes,
  fulfilled,
}: Props) {
  const { timeInSeconds, hours, minutes, seconds, isExpired, isLastDay } =
    useRemainingTime(remainingDays, remainingHoursAndMinutes);

  if (isExpired || fulfilled) {
    return (
      <Badge className={classes.badge} radius="lg" size="lg" color="red">
        募集終了
      </Badge>
    );
  }

  if (isLastDay) {
    // 1時間前の場合
    if (timeInSeconds < 3600) {
      const formattedTime = `${minutes}分${seconds}秒`;
      return (
        <Badge
          className={classes.badge}
          radius="lg"
          size="lg"
          color="pink"
          leftSection={<TimeFiveIcon size="0.8rem" />}
        >
          応募締切まで：{formattedTime}
        </Badge>
      );
    }
    const formattedTime = `${hours}時間${minutes}分`;
    return (
      <Badge
        className={classes.badge}
        radius="lg"
        size="lg"
        color="grape"
        leftSection={<TimeFiveIcon size="0.8rem" />}
      >
        応募締切まで：{formattedTime}
      </Badge>
    );
  }

  return (
    <Badge
      className={classes.badge}
      radius="lg"
      size="lg"
      leftSection={<TimeFiveIcon size="0.8rem" />}
    >
      応募締切まで：{remainingDays}日
    </Badge>
  );
}
