import Image from "next/image";
import Link from "next/link";

import {
  TimeFiveIcon,
  Group,
  Text,
  Card,
  H2,
  Badge,
  PlaceIcon,
  Rating,
} from "@/components/ui/elements";
import { IconLabel } from "@/components/ui/parts";

import { TemporaryJobOfferFeedItemFieldsFragment } from "@/gql/__generated__/graphql";

import classes from "./_css/WorkCard.module.css";
import { CampaignLabel } from "./CampaignLabel";
import { JobTypeBadge } from "./JobTypeBadge";
import { WorkFavorite } from "./WorkFavorite";
import { WorkTags } from "./WorkTags";
import { WorkTimeRemaining } from "./WorkTimeRemaining";

type Props = {
  jobOffer: TemporaryJobOfferFeedItemFieldsFragment;
};

export function WorkCard({
  jobOffer: {
    temporaryJobOffer: { id, title, status },
    tags,
    deadline,
    remainingDays,
    remainingHoursAndMinutes,
    officeName,
    jobCategoryName,
    businessCategoryName,
    imageUrl,
    workTimePeriod,
    perHourWage,
    workDate,
    numberOfRemainingRecruitment,
    transportExpenseAmount,
    favorited,
    fulfilled,
    wageAmount,
    workPlaceAddress,
    reviewingRate,
    reviewingCount,
    isAnyLinkedJobOffersClosed,
    waitlist,
    campaign,
  },
}: Props) {
  const noimage =
    "https://placehold.jp/30/dd6699/ffffff/300x150.png?text=placeholder+image";
  const isWelfare = businessCategoryName === "福祉";
  const amount = Number(wageAmount) + transportExpenseAmount;
  const isTimeClose =
    remainingHoursAndMinutes === "00:00" || isAnyLinkedJobOffersClosed;
  const isCapacityClose = fulfilled;
  const isClosed = isTimeClose || isCapacityClose;
  return (
    <div className={classes.container}>
      <WorkTimeRemaining
        fulfilled={fulfilled}
        remainingDays={remainingDays}
        remainingHoursAndMinutes={
          remainingHoursAndMinutes ? remainingHoursAndMinutes : "00:00"
        }
      />

      <Card
        withBorder
        shadow="lg"
        radius="md"
        p="md"
        bg={"gray.0"}
        component={Link}
        href={`/work/${id}`}
        className={classes.card}
      >
        <Card.Section className={classes.image} h={180} pos="relative">
          {campaign && <CampaignLabel amount={campaign.amount} />}
          <WorkFavorite isFavorite={favorited} temporaryJobOfferId={id} />
          <Image
            quality={30}
            loading="lazy"
            sizes="(max-width: 768px) 100vw, 300px"
            src={imageUrl ? imageUrl : noimage}
            alt={title}
            fill
          />
        </Card.Section>

        <Card.Section className={classes.section} mt="xs">
          <JobTypeBadge businessCategoryName={businessCategoryName} />
          <Group justify="space-between">
            <H2>{title}</H2>
          </Group>
          <Group gap="xs" pt={0}>
            {tags && <WorkTags tags={tags} />}
            <Badge color={"orange"} variant="light" size="md" radius="lg">
              {jobCategoryName}
            </Badge>
          </Group>
          <Group justify="space-between" wrap="nowrap" gap="xs">
            <Group>
              <IconLabel
                icon={<TimeFiveIcon color="teal" />}
                label={`${workDate}${workTimePeriod}`}
                labelProps={{ fz: "sm", fw: "bold" }}
              />
            </Group>
            <div style={{ minWidth: "135px" }}>
              <Text ta="center" fz={20} fw={800}>
                {amount.toLocaleString()}円
              </Text>
              <Text fz="sm" ta="center">
                （交通費 {transportExpenseAmount.toLocaleString()}円込み）
              </Text>
            </div>
          </Group>
          <Group py={5} grow>
            <IconLabel
              icon={<PlaceIcon color="teal" />}
              label={workPlaceAddress ? workPlaceAddress : ""}
              labelProps={{ fz: "sm" }}
            />
          </Group>
          <Group py={5}>
            {reviewingRate ? (
              <>
                <Rating
                  fractions={10}
                  value={reviewingRate}
                  readOnly
                  size="xs"
                />
                <Text px={10} fz="sm" fw={500}>
                  {reviewingRate.toFixed(2)}（{reviewingCount}件）
                </Text>
              </>
            ) : (
              <>
                <Rating fractions={10} value={0} readOnly size="xs" />
                <Text px={10} fz="sm" fw={500} c="dimmed">
                  評価がまだありません
                </Text>
              </>
            )}
          </Group>
          <Group pt={5} justify="space-between" wrap="nowrap" gap="xs">
            <Text fz="sm" fw={800} c="teal.8">
              {officeName}
            </Text>
            {!isClosed && (
              <Text ta="right" miw={100} fz="md" fw={800} color="red">
                {numberOfRemainingRecruitment}
              </Text>
            )}
            {isCapacityClose && (
              <Text ta="right" miw={100} fz="sm" fw={800} color="red">
                定員満了
                {!isTimeClose && (
                  <>
                    {waitlist.canEntry && <>(キャンセル待ち受付中)</>}
                    {waitlist.isEntered && <>(キャンセル待ち登録済)</>}
                  </>
                )}
              </Text>
            )}
            {!isCapacityClose && isTimeClose && (
              <Text ta="right" miw={100} fz="sm" fw={800} color="red">
                締切時間
              </Text>
            )}
          </Group>
        </Card.Section>
      </Card>
    </div>
  );
}
