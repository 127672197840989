import Kuroshiro from "kuroshiro";
// Initialize kuroshiro with an instance of analyzer (You could check the [apidoc](#initanalyzer) for more information):
// For this example, you should npm install and import the kuromoji analyzer first
import KuromojiAnalyzer from "kuroshiro-analyzer-kuromoji";

export async function convertToHiragana(input: string) {
  // Instantiate
  const kuroshiro = new Kuroshiro();
  // Initialize
  // Here uses async/await, you could also use Promise
  await kuroshiro.init(new KuromojiAnalyzer({ dictPath: "/dict" }));
  // Convert what you want
  const result = await kuroshiro.convert(input, { to: "hiragana" });
  return result;
}
