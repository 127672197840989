import { graphql } from "@/gql/__generated__";

export const updateBankAccountMutation = graphql(`
  mutation UpdateBankAccount($input: UpdateBankAccountInput!) {
    updateBankAccount(input: $input) {
      temporaryJobWorker {
        id
      }
    }
  }
`);

export const bankAccountQuery = graphql(`
  query BankAccount {
    bankAccount {
      accountHolderName
      accountNumber
      accountType
      bankCode
      bankName
      branchCode
      branchName
      id
    }
  }
`);
