import { useEffect, useRef, useState } from "react";

import { Loader, Select } from "@/components/ui/elements";

import { isErrorResponse, useGetBankBranches } from "@/hooks/bank";

type Props = {
  bankcode: string;
  defaultbranch: string | undefined;
  defaultbranchName: string | undefined;
  getBranchCode: (code: string) => void;
  getBranchName: (name: string) => void;
};

type BankItem = {
  value: string;
  label: string;
};

export function BranchNameInput({
  bankcode,
  defaultbranch,
  defaultbranchName,
  getBranchCode,
  getBranchName,
}: Props) {
  const [brancheList, setBrancheList] = useState<BankItem[]>([]);
  const [searchValue, onSearchChange] = useState(
    defaultbranchName ? defaultbranchName : "",
  );
  const mountedRef = useRef(false);
  const timeoutRef = useRef<number | null>(null);
  const cooldownRef = useRef(false);
  const cancel = () => window.clearTimeout(timeoutRef.current!);
  const { getBranches, loading } = useGetBankBranches();

  function filterBranch(query: string) {
    getBranches(bankcode, query)
      .then((result) => {
        if (!result) return;
        if (isErrorResponse(result)) return setBrancheList([]);
        if (!result.length) return setBrancheList([]);
        const list = result.map((item) => {
          return { value: item.code, label: item.name };
        });
        setBrancheList([...list]);
      })
      .catch(() => {
        return setBrancheList([]);
      });
  }

  useEffect(() => {
    mountedRef.current = true;
    return cancel;
  }, []);

  useEffect(() => {
    if (mountedRef.current) {
      if (!cooldownRef.current) {
        cooldownRef.current = true;
        filterBranch(searchValue);
      } else {
        cancel();
        timeoutRef.current = window.setTimeout(() => {
          cooldownRef.current = false;
          filterBranch(searchValue);
        }, 200);
      }
    }
  }, [searchValue]);

  return (
    <Select
      defaultValue={defaultbranch}
      withAsterisk
      data={brancheList}
      filter={() => brancheList}
      searchable
      onSearchChange={onSearchChange}
      searchValue={searchValue}
      placeholder=""
      description="支店名を入力し、入力後に表示される候補の中から選択してください。"
      maw={200}
      label="支店名"
      nothingFoundMessage={loading ? <Loader /> : "支店が見つかりません。"}
      limit={20}
      onChange={(value: string) => getBranchCode(value)}
      onBlur={(e) => getBranchName(e.target.value)}
      rightSection={loading ? <Loader size="xs" /> : <div></div>}
    />
  );
}
