import { useEffect } from "react";

import Link from "next/link";
import { useRouter } from "next/navigation";

import { logError } from "@/libs/loging/slack";

import {
  Button,
  Center,
  Container,
  H2,
  Stack,
  Text,
} from "@/components/ui/elements";

type Props = {
  clientErrors: readonly Error[];
};

export function ClientErrors({ clientErrors }: Props) {
  const router = useRouter();
  const errorMessage = clientErrors?.[0]?.message || "エラーメッセージなし";
  useEffect(() => {
    logError({
      errorHandling: "clientErrors",
      message: errorMessage,
      type: clientErrors?.[0]?.name || "エラー名なし",
      url: window.location.href,
      userAgent: window.navigator.userAgent,
      stack: clientErrors?.[0]?.stack || "スタックトレースなし",
    });
  }, [clientErrors]);
  return (
    <Container fluid bg="gray.0" mih={"100dvh"}>
      <Center w={"100%"} h={"100dvh"}>
        <Stack>
          <H2>クライアントでエラーが発生しました。</H2>
          <Text>
            解決しない場合は管理者にお問い合わせください。
            <br />
            以下のエラーが発生しています。
          </Text>
          {clientErrors.map((e, i) => (
            <Text key={i}>
              エラー名：{e.name || "エラー名なし"}
              エラーメッセージ：{e.message || "エラーメッセージなし"}
            </Text>
          ))}
          <Button onClick={router.back}>戻る</Button>
          <Button component={Link} href={"/signup"}>
            ログイン画面へ戻る
          </Button>
        </Stack>
      </Center>
    </Container>
  );
}
