import { useDisclosure } from "@mantine/hooks";
import Image from "next/image";

import {
  Button,
  TimeFiveIcon,
  Card,
  Group,
  Text,
  Modal,
  Stack,
  Badge,
  H1,
} from "@/components/ui/elements";
import { IconLabel } from "@/components/ui/parts";

import { Review } from "../review";
import classes from "./_css/WorkCompleteCard.module.css";

export function WorkCompleteCard({
  id,
  title,
  tags,
  officeName,
  jobCategoryName,
  imageUrl,
  perHourWage,
  workDate,
  onReviewComplete,
}: {
  id: string;
  title: string;
  tags: string[] | null;
  officeName: string;
  jobCategoryName: string;
  imageUrl: string;
  perHourWage: string;
  workDate: string | null;
  onReviewComplete: (average: number) => void;
}) {
  const [opened, { open, close }] = useDisclosure(false);

  const noimage =
    "https://placehold.jp/30/dd6699/ffffff/300x150.png?text=placeholder+image";

  const handleReviewComplete = (avg: number) => {
    close();
    onReviewComplete(avg);
  };

  return (
    <>
      <Card withBorder shadow="lg" radius="md" p="md" className={classes.card}>
        <Card.Section className={classes.image} h={250}>
          <Badge color="orange" className={classes.badge}>
            未レビュー
          </Badge>
          <Image
            src={imageUrl ? imageUrl : noimage}
            alt={jobCategoryName}
            fill
          />
        </Card.Section>

        <Card.Section className={classes.section} mt="md">
          <Stack>
            <Group py={5} justify="between">
              <H1>{title}</H1>
            </Group>
            <Group py={5} justify="between">
              <Group>
                <IconLabel
                  icon={<TimeFiveIcon />}
                  label={workDate}
                  labelProps={{ fz: "sm" }}
                />
              </Group>
              <div>
                <Text fz="md" fw={800}>
                  {perHourWage}
                </Text>
                <Text fz="sm">（交通費800円込み）</Text>
              </div>
            </Group>
          </Stack>

          <Group pt={10} justify="between">
            <Button color="teal" onClick={open}>
              レビューする
            </Button>
          </Group>
        </Card.Section>
      </Card>
      <Modal opened={opened} close={close} size="xl">
        <Review
          close={close}
          temporaryJobOfferId={id}
          onReviewComplete={handleReviewComplete}
        />
      </Modal>
    </>
  );
}
