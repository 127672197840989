"use client";
import { useForm } from "react-hook-form";

import { useMutation, useSuspenseQuery } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input, Stack } from "@mantine/core";
import { formatISO } from "date-fns";
import { useRouter } from "next/navigation";
import { z } from "zod";

import {
  Button,
  Center,
  Divider,
  Group,
  H2,
  Paper,
  Text,
} from "@/components/ui/elements";
import {
  DatePickerInputField,
  InputField,
  RadioField,
} from "@/components/ui/parts";
import { MultiSelectModal } from "@/components/ui/parts/multiSelectModal";

import { Sex } from "@/gql/__generated__/graphql";
import { useAddressByZipCode } from "@/hooks/zip";
import { i18n, infoMessage } from "@/i18n";
import {
  birthdayValidation,
  buildingValidation,
  cityValidation,
  experienceYearsValidation,
  firstNameValidation,
  imageValidation,
  lastNameValidation,
  occupationsValidation,
  prefectureValidation,
  sexValidation,
  streetValidation,
  zipCodeValidation,
} from "@/types/validation";
import { showNotification } from "@/util/userNotifications";

import {
  businessGenresQuery,
  occupationQuery,
  updateProfileMutation,
} from "./_graphql";
import { EmploymentPeriod, employmentPeriod } from "./_type";
import { UserImageFields } from "./UserImageFields";

const schema = z.object({
  image: imageValidation(),
  defaultImage: z.string().or(z.undefined()),
  firstName: firstNameValidation(),
  lastName: lastNameValidation(),
  birthday: birthdayValidation(),
  sex: sexValidation(),
  occupation: z.union([occupationsValidation(), z.undefined()]),
  zipCode: zipCodeValidation(),
  prefecture: prefectureValidation(),
  city: cityValidation(),
  street: streetValidation(),
  building: z.union([buildingValidation(), z.undefined()]),
  experienceYears: z.union([experienceYearsValidation(), z.undefined()]),
  experienceWork: z.union([z.string().array(), z.undefined()]),
});

type Schema = z.infer<typeof schema>;

export function UserProfileForm({
  profile,
  address,
  caregiverExperience,
  destinationPath,
}: {
  profile?: {
    id: string;
    birthday: string;
    firstName: string;
    imageId: string | null;
    imageUrl: string | null;
    lastName: string;
    sex: Sex;
    occupation: {
      id: string;
      name: string;
    };
  } | null;
  address?: {
    postalCode: string;
    prefecture: string;
    city: string;
    street: string;
    building: string | null;
  };
  caregiverExperience?: {
    id: string;
    businessGenres: {
      id: string;
      name: string;
    }[];
    employmentPeriod: string | null;
  } | null;
  destinationPath: string;
}) {
  const {
    control,
    watch,
    getValues,
    setValue,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<Schema>({
    mode: "onSubmit",
    defaultValues: {
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      image: undefined,
      defaultImage: profile?.imageUrl ? profile.imageUrl : undefined,
      sex: profile?.sex,
      zipCode: address?.postalCode,
      prefecture: address?.prefecture,
      city: address?.city,
      street: address?.street,
      building: address?.building ?? "",
      occupation: profile?.occupation.id,
      birthday: profile ? new Date(profile.birthday) : undefined,
      experienceYears:
        caregiverExperience?.employmentPeriod as EmploymentPeriod,
      experienceWork: caregiverExperience?.businessGenres.map((j) => j.id),
    },
    resolver: zodResolver(schema),
  });
  const {
    data: { occupations },
  } = useSuspenseQuery(occupationQuery, { fetchPolicy: "cache-first" });
  const occupationItem = occupations.map((occupation) => {
    return { value: occupation.id, label: occupation.name };
  });
  const {
    data: { businessGenres },
  } = useSuspenseQuery(businessGenresQuery, { fetchPolicy: "cache-first" });
  const jobCategoryItem = businessGenres.map((businessGenre) => {
    return { value: businessGenre.id, label: businessGenre.name };
  });
  const [mutation, { loading }] = useMutation(updateProfileMutation, {
    refetchQueries: ["MyPage", "Profile", "Viewer"],
  });
  const { getAddress, loading: adressLoading } = useAddressByZipCode();
  const router = useRouter();
  const handleGetAdd = async () => {
    const zipcode = getValues("zipCode");
    if (!zipcode) return;
    const { address } = await getAddress(zipcode);
    setValue("prefecture", address.prefecture);
    setValue("city", address.city);
    setValue("street", address.street);
  };
  const defaultImage = watch("defaultImage");

  function isBase64(input: string) {
    return /^data:(image|application)\/[a-z]+;base64,/.test(input);
  }
  const onSubmit = async (data: Schema) => {
    if (!data.image && !data.defaultImage) {
      return setError(
        "image",
        {
          type: "required",
          message: "画像をアップロードしてください。",
        },
        { shouldFocus: true },
      );
    }
    await mutation({
      variables: {
        input: {
          image: data.image,
          birthday: formatISO(data.birthday, { representation: "complete" }),
          firstName: data.firstName ?? "",
          lastName: data.lastName ?? "",
          sex: data.sex ?? "male",
          occupationId: data.occupation,
          postalCode: data.zipCode,
          prefecture: data.prefecture,
          city: data.city,
          street: data.street,
          building: data.building,
          businessGenreIds: data.experienceWork,
          employmentPeriod: data.experienceYears,
        },
      },
      onError: (error) =>
        showNotification({
          type: "error",
          message: error.message,
        }),
      onCompleted: (data) => {
        const res = data.updateResumeProfile;
        if (!res) return;
        showNotification({
          type: "info",
          message: infoMessage("addProfile"),
        });
        router.push(destinationPath);
      },
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Paper>
          <UserImageFields
            control={control}
            name="image"
            errors={errors}
            defaultImage={defaultImage}
          />
        </Paper>
        <Paper>
          <Stack>
            <H2>プロフィール詳細</H2>
            <Text>
              <span style={{ color: "red" }}>*</span>
              は求人応募する際に必須事項となります。
            </Text>
            <Stack gap="xl">
              <div>
                <Input.Label required>お名前</Input.Label>
                <Group align="flex-end">
                  <InputField
                    label={i18n("lastName")}
                    maw={200}
                    name="lastName"
                    control={control}
                    placeholder="例：山田"
                  />
                  <InputField
                    label={i18n("firstName")}
                    maw={200}
                    name="firstName"
                    control={control}
                    placeholder="例：太郎"
                  />
                </Group>
              </div>
              <div>
                <Input.Label required>{i18n("birthday")}</Input.Label>
                <DatePickerInputField
                  maw={150}
                  name="birthday"
                  control={control}
                  defaultValue={new Date(2000, 0, 1)}
                />
              </div>
              <RadioField
                withAsterisk
                label={i18n("sex")}
                name="sex"
                data={[
                  { value: "male", label: "男性" },
                  { value: "female", label: "女性" },
                ]}
                control={control}
              />

              <Group align="flex-end">
                <InputField
                  maw={200}
                  label={i18n("zipCode")}
                  name="zipCode"
                  description="※半角英数字でハイフンなしで入力"
                  control={control}
                  withAsterisk
                  placeholder="例：2600855"
                />
                <Button
                  loading={adressLoading}
                  size="sm"
                  onClick={handleGetAdd}
                >
                  自動入力
                </Button>
              </Group>
              <div>
                <Input.Label required>住所</Input.Label>
                <Group>
                  <InputField
                    label={i18n("prefecture")}
                    name="prefecture"
                    control={control}
                    withAsterisk
                    placeholder="例：千葉県"
                  />
                  <InputField
                    label={i18n("city")}
                    name="city"
                    control={control}
                    withAsterisk
                    placeholder="例：千葉市"
                  />
                </Group>
                <InputField
                  maw={400}
                  label={i18n("street")}
                  name="street"
                  control={control}
                  withAsterisk
                  placeholder="例：中央区市場町１−１"
                />
                <InputField
                  maw={400}
                  label={i18n("building")}
                  name="building"
                  control={control}
                  placeholder="例：ケアリンクビル３階"
                />
              </div>
              <MultiSelectModal
                withAsterisk
                name="occupation"
                label={i18n("occupation")}
                type="radio"
                watch={watch}
                maw={250}
                data={occupationItem}
                control={control}
                error={errors.occupation?.message}
              />
              <Divider />

              <Text fw="bold">介護職の経験</Text>
              <MultiSelectModal
                maw={250}
                name="experienceYears"
                label={i18n("experienceYears")}
                type="radio"
                watch={watch}
                data={employmentPeriod}
                control={control}
                error={errors.experienceYears?.message}
              />
              <MultiSelectModal
                name="experienceWork"
                label={i18n("experienceWork")}
                type="checkbox"
                watch={watch}
                data={jobCategoryItem}
                control={control}
                error={errors.experienceWork?.message}
              />
              <Center>
                <Button size="md" type="submit" loading={loading}>
                  プロフィールを保存
                </Button>
              </Center>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </form>
  );
}
