import {
  Control,
  FieldErrors,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";

import { FileButton, FileInput, Input } from "@mantine/core";

import { Avatar, Group, H2, Stack, Text } from "@/components/ui/elements";

type Props<FORM_TYPE extends FieldValues> = {
  control: Control<FORM_TYPE>;
  name: FieldPath<FORM_TYPE>;
  errors?: FieldErrors<FORM_TYPE>;
  defaultImage?: string;
};

export function UserImageFields<FORM_TYPE extends FieldValues>({
  control,
  name,
  errors,
  defaultImage,
}: Props<FORM_TYPE>) {
  const { field } = useController({
    name,
    control,
  });

  const imageSrc = field.value ? URL.createObjectURL(field.value) : null;
  return (
    <Stack>
      <H2>
        <Input.Label required fw="bold" mb={5}>
          プロフィール画像
        </Input.Label>
      </H2>
      <Stack>
        <Group justify="center" h={120} style={{ position: "relative" }}>
          <FileButton onChange={field.onChange} accept="image/png,image/jpeg">
            {(props) => (
              <Avatar
                variant="outline"
                color="gray"
                h={120}
                w={120}
                {...props}
                src={imageSrc || defaultImage || null}
              />
            )}
          </FileButton>

          <FileInput
            variant="unstyled"
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            ref={field.ref}
            accept="image/png,image/jpeg"
            valueComponent={(file) => {
              if (!file && !defaultImage) {
                return "未設定";
              }
              return "";
            }}
          />
        </Group>
        <Text ta="center" fw="bold" c="teal">
          タップして画像を変更
        </Text>
        {errors?.[name] && (
          <Input.Error>{`${errors[name]?.message}`}</Input.Error>
        )}
        <Input.Description>
          ※ 3か月以内に撮影されたものを推奨
          <br />※ お顔立ちが分かる真正面の写真を推奨
        </Input.Description>
        {/* <Stack gap={5}>
          <Text c="teal" fw="bold" fz="md">
            good
          </Text>
          <Flex align="center" gap="xs">
            <CheckIcon color="teal" />
            <Text>3か月以内の撮影</Text>
          </Flex>
          <Flex align="center" gap="xs">
            <CheckIcon color="teal" />
            <Text>正面を向いた笑顔がベスト</Text>
          </Flex>
        </Stack>
        <Stack gap={5}>
          <Text c="red" fw="bold" fz="md">
            bad
          </Text>
          <Flex align="center" gap="xs">
            <CloseIcon color="red" />
            <Text>複数人で写っている写真</Text>
          </Flex>
          <Flex align="center" gap="xs">
            <CloseIcon color="red" />
            <Text>マスクやサングラスをしている写真</Text>
          </Flex>
          <Flex align="center" gap="xs">
            <CloseIcon color="red" />
            <Text>幼少期の写真</Text>
          </Flex>
          <Flex align="center" gap="xs">
            <CloseIcon color="red" />
            <Text>著しく加工した写真</Text>
          </Flex>
        </Stack> */}
      </Stack>
    </Stack>
  );
}
