import { rem } from "@mantine/core";

import { Center, Container, H2, Stack, Text } from "@/components/ui/elements";

export function MaintenanceError() {
  return (
    <Container fluid bg="gray.0" mih={`calc(100vh - (${rem(70)} + ${rem(40)})`}>
      <Center w="100%" h={`calc(100vh - (${rem(70)} + ${rem(40)})`}>
        <Stack align="center" gap="md">
          <H2>ただいまメンテナンス作業を実施しております</H2>
          <Text>
            ご不便をおかけし申し訳ございません。
            <br />
            システムの品質向上のため、現在メンテナンス作業を行っております。
            <br />
            しばらく時間をおいてから再度アクセスをお願いいたします。
          </Text>
        </Stack>
      </Center>
    </Container>
  );
}
