import json from "@/i18n/ja/common.json";
import errorjson from "@/i18n/ja/error.json";
import infojson from "@/i18n/ja/info.json";

type Json = typeof json;
type JsonKey = keyof typeof json;
type JsonValue = Json[JsonKey];

export const i18n = (key: JsonKey): JsonValue => {
  const termData: Json = json;
  if (termData.hasOwnProperty(key)) {
    // キーの存在をチェック
    return termData[key];
  } else {
    console.warn(`用語「${key}」は定義されていません。`);
    return key; // 未定義の場合は元の用語キーを返す
  }
};

type ErrorJson = typeof errorjson;
type ErrorJsonKey = keyof typeof errorjson;
type ErrorJsonValue = ErrorJson[ErrorJsonKey];

export const errorMessage = (key: ErrorJsonKey): ErrorJsonValue => {
  const termData: ErrorJson = errorjson;
  if (termData.hasOwnProperty(key)) {
    // キーの存在をチェック
    return termData[key];
  } else {
    console.warn(`エラーメッセージ「${key}」は定義されていません。`);
    return key; // 未定義の場合は元の用語キーを返す
  }
};

type InfoJson = typeof infojson;
type InfoJsonKey = keyof typeof infojson;
type InfoJsonValue = InfoJson[InfoJsonKey];

export const infoMessage = (key: InfoJsonKey): InfoJsonValue => {
  const termData: InfoJson = infojson;
  if (termData.hasOwnProperty(key)) {
    // キーの存在をチェック
    return termData[key];
  } else {
    console.warn(`インフォメッセージ「${key}」は定義されていません。`);
    return key; // 未定義の場合は元の用語キーを返す
  }
};
