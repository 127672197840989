import { useState } from "react";
import { useForm } from "react-hook-form";

import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@mantine/core";
import { useTimeout } from "@mantine/hooks";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { z } from "zod";

import { updateBankAccountMutation } from "@/app/(logedIn)/mypage/bankAccount/_graphql";

import {
  Button,
  Center,
  Group,
  H2,
  Loader,
  Stack,
} from "@/components/ui/elements";
import { AlertField, InputField, SelectField } from "@/components/ui/parts";

import { BankAccount } from "@/gql/__generated__/graphql";
import { infoMessage } from "@/i18n";
import {
  accountHolderValidation,
  accountNumberValidation,
  accountTypeValidation,
  bankNameValidation,
  bankcodeValidation,
  branchNameValidation,
  branchcodeValidation,
} from "@/types/validation";
import { showNotification } from "@/util/userNotifications";

import { BankNameInput } from "./BankNameInput";
import { BranchNameInput } from "./BranchNameInput";

type Props = {
  defaultValue?: {
    accountHolderName: string;
    accountNumber: string;
    accountType: BankAccount;
    bankCode: string;
    bankName: string;
    branchCode: string;
    branchName: string;
    id: string;
  };
  destinationPath: string;
  isMyPage?: boolean;
};

const schema = z.object({
  bankCode: bankcodeValidation(),
  bankName: bankNameValidation(),
  branchCode: branchcodeValidation(),
  branchName: branchNameValidation(),
  accountType: accountTypeValidation(),
  accountNumber: accountNumberValidation(),
  accountHolderLastName: accountHolderValidation(),
  accountHolderFirstName: accountHolderValidation(),
});

type Schema = z.infer<typeof schema>;

export function BankForm({ destinationPath, defaultValue, isMyPage }: Props) {
  const router = useRouter();
  const [bankLoading, setBankLoading] = useState(false);
  const { start, clear } = useTimeout(() => setBankLoading(false), 300);
  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm<Schema>({
    mode: "onBlur",
    resolver: zodResolver(schema),
    defaultValues: defaultValue
      ? {
          bankCode: defaultValue.bankCode,
          bankName: defaultValue.bankName,
          branchCode: defaultValue.branchCode,
          branchName: defaultValue.branchName,
          accountType: defaultValue.accountType,
          accountNumber: defaultValue.accountNumber,
          accountHolderLastName: defaultValue.accountHolderName.split("　")[0], // 全角スペースで分ける
          accountHolderFirstName: defaultValue.accountHolderName.split("　")[1], // 全角スペースで分ける
        }
      : {
          bankCode: undefined,
          bankName: undefined,
          branchCode: undefined,
          branchName: undefined,
          accountType: "ordinary",
          accountNumber: undefined,
          accountHolderLastName: undefined,
          accountHolderFirstName: undefined,
        },
  });

  const [mutation, { error, loading }] = useMutation(
    updateBankAccountMutation,
    {
      refetchQueries: ["BankAccount", "Viewer"],
    },
  );
  const onSubmit = async (data: Schema) => {
    await mutation({
      variables: {
        input: {
          accountHolderName: `${data.accountHolderLastName}　${data.accountHolderFirstName}`,
          accountNumber: data.accountNumber,
          accountType: data.accountType,
          bankCode: data.bankCode,
          bankName: data.bankName,
          branchCode: data.branchCode,
          branchName: data.branchName,
        },
      },
      onError: (error) =>
        showNotification({
          type: "error",
          message: error.message,
        }),
      onCompleted: (data) => {
        const res = data.updateBankAccount;
        if (!res) return;
        showNotification({ type: "info", message: infoMessage("addBank") });
        router.push(destinationPath);
      },
    });
  };

  const getBankCode = (code: string) => {
    setBankLoading(true);
    setValue("bankCode", code);
    start();
  };
  const getBankName = (name: string) => {
    setValue("bankName", name);
  };
  const getBranchCode = (code: string) => {
    setValue("branchCode", code);
  };
  const getBranchName = (name: string) => {
    setValue("branchName", name);
  };
  const defaultbank = getValues("bankCode");
  const defaultbankName = getValues("bankName");
  const defaultbranch = getValues("branchCode");
  const defaultbranchName = getValues("branchName");
  const infoMessages = `・振込先の口座確認は行いませんので、入力内容を十分ご確認上口座をご登録下さい。
・振込ができなかった場合でも振込手数料は返金しません。`;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <H2>報酬を受け取る口座を登録してください。</H2>
        <Group align="flex-end">
          <BankNameInput
            defaultbank={defaultbank}
            defaultbankName={defaultbankName}
            getBankCode={getBankCode}
            getBankName={getBankName}
          />
          <InputField
            maw={150}
            label="金融機関コード"
            name="bankCode"
            control={control}
            disabled
          />
        </Group>
        <Group align="flex-end">
          {bankLoading ? (
            <Center>
              <Loader />
            </Center>
          ) : (
            <BranchNameInput
              defaultbranch={defaultbranch}
              defaultbranchName={defaultbranchName}
              bankcode={watch("bankCode")}
              getBranchCode={getBranchCode}
              getBranchName={getBranchName}
            />
          )}

          <InputField
            maw={150}
            label="支店コード"
            name="branchCode"
            control={control}
            disabled
          />
        </Group>

        <SelectField
          withAsterisk
          maw={200}
          label="口座種別"
          name="accountType"
          control={control}
          data={[
            { value: "ordinary", label: "普通" },
            { value: "checking", label: "当座" },
          ]}
        />
        <InputField
          withAsterisk
          maw={200}
          label="口座番号7桁"
          name="accountNumber"
          control={control}
        />
        <div>
          <Input.Label required>口座名義人※カタカナのみ</Input.Label>
          <Group align="flex-end">
            <InputField
              withAsterisk
              maw={200}
              label="セイ"
              name="accountHolderLastName"
              control={control}
            />
            <InputField
              withAsterisk
              maw={200}
              label="メイ"
              name="accountHolderFirstName"
              control={control}
            />
          </Group>
        </div>

        <Center>
          <Button type="submit" loading={loading}>
            口座を登録する
          </Button>
        </Center>
        {!isMyPage && (
          <Center>
            <Button component={Link} href={destinationPath} variant="outline">
              後で登録する
            </Button>
          </Center>
        )}

        <Center>
          <AlertField
            type="notice"
            maw={500}
            title="info"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {infoMessages}
          </AlertField>
        </Center>
      </Stack>
    </form>
  );
}
