import Image from "next/image";

import { Avatar, Group, Stack, Text } from "@/components/ui/elements";

import classes from "./_css/UserImage.module.css";

export function UserImage({ image }: { image: File[] | string | undefined }) {
  if (image && typeof image === "string") {
    return (
      <Group className={classes.image} justify="center">
        <Image src={image} alt="image" fill />
      </Group>
    );
  }
  if (Array.isArray(image) && image.every((image) => image instanceof File)) {
    const imageUrl = URL.createObjectURL(image[0]);
    return (
      <Group className={classes.image} justify="center">
        <Image src={imageUrl} alt="image" fill />
      </Group>
    );
  }
  return (
    <Stack align="center">
      <Avatar size="lg" alt="it's me" />
      <Text fz="md" c="teal" fw="bold">
        タップして画像を変更
      </Text>
    </Stack>
  );
}
