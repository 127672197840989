import { Badge } from "@/components/ui/elements";

type Props = {
  tags: string[];
};

export function WorkTags({ tags }: Props) {
  return tags.map((tag, i) => (
    <Badge
      variant="light"
      key={i}
      size="md"
      radius="lg"
      color={tag === "連動勤務" ? "grape" : undefined}
    >
      {tag}
    </Badge>
  ));
}
